import React from 'react';
import './App.css';

class App extends React.Component {
  public render() {
    return (
      <main>
        <h2>hello app</h2>
      </main>
    );
  }
}

export default App;
